.apresentacao {
  display: flex;
}

.home {
    background-color: white;
    margin-top: 5% !important;
}

.sobreMim {
  display: flex;
}

.sobreMimGridImage {
  margin-left: 10% !important;
}

.sobreMimGrid {
  display: flex;
}

.especialidadesTitle {
  margin-left: 10% !important;
  margin-top: 1rem !important;
}

.duvidas {
  display: flex;
}

.duvidasGrid {
  margin-left: 10% !important;
}

.duvidasTitle {
  margin-left: 10% !important;
  margin-top: 1rem !important;
}

.contatosTitle {
  margin-left: 10% !important;
  margin-top: 1rem !important;
}

@media (max-width: 760px) {
  .apresentacao {
    display: none;
  }
}

@media (max-width: 990px) {

  .home {
    margin-top: 10% !important;
  }

  .sobreMim {
    display: block;
  }

  .sobreMimGridImage {
    display: none;
  }

  .sobreMimGrid {
    display: none;
  }

  .sobreMimGridImageResponsive {
    display: grid;
    justify-items: center;
  }

  .sobreMimGridResponsive {
    display: grid;
    margin-left: 8% !important;
    margin-right: 9% !important;
  }

  .especialidadesTitle {
    margin-left: 8% !important;
    margin-top: 1rem !important;
  }

  .especialidades {
    display: none !important;
  }

  .especialidadesResponsive {
    display: flex !important;
  }

  .duvidas {
    display: block;
  }

  .duvidasGrid {
    display: none;
  }

  .duvidasGridResponsive {
    margin-left: 8% !important;
  }

  .duvidasTitle {
    margin-left: 8% !important;
    margin-top: 1rem !important;
  }

  .duvidasGridTitle {
    display: none;
  }

  .duvidasGridImage {
    display: none;
  }

  .duvidasGridImageResponsive {
    display: grid;
    justify-items: center;
  }

  .contatosTitle {
    margin-left: 8% !important;
    margin-top: 1rem !important;
  }

  .contatos {
    display: none !important;
  }

  .contatosResponsive {
    display: grid;
    
  }

}

@media (min-width: 760px) {
  .apresentacaoResponsiva {
    display: none;
  }
}

@media (min-width: 990px) {

  .sobreMimGridImageResponsive {
    display: none;
  }

  .sobreMimGridResponsive {
    display: none;
  }

  .especialidades {
    display: flex !important;
  }

  .especialidadesResponsive {
    display: none !important;
  }

  .duvidasGridResponsive {
    display: none;
  }

  .duvidasGridImageResponsive {
    display: none;
  }

  .contatosResponsive {
    display: none !important;
  }

  .duvidasGridTitleResponsive {
    display: none;;
  }

}
  