

.sobreMimFrase {
    color: #737373;
    font-size: 1rem;
    font-weight: bold;
    font-style: italic;
}

    @media (max-width: 1200px) {
        .sobreMimFraseResponsive {
            color: #737373;
            font-size: 1rem;
            font-weight: bold;
            font-style: italic;
        }

        .sobreMimFrase {
            display: none;
        }
    }
  
    @media (min-width: 1200px) {
        .sobreMimFraseResponsive {
            display: none;
        }
    }

    @media (min-width: 419px) {
        .sobreMimFraseResponsiveMini {
            display: none;
        }

        .sobreMimNomeAutorMini {
            display: none;
        }
    }

    @media (max-width: 419px) {
        .sobreMimFraseResponsive {
            display: none;
        }

        .sobreMimFrase {
            display: none;
        }

        .sobreMimFraseResponsiveMini {
            color: #737373;
            font-size: .8rem;
            font-weight: bold;
            font-style: italic;
        }

        .sobreMimNomeAutor {
            display: none;
        }

        .sobreMimNomeAutorMini {
            font-size: .8rem;
        }

    }
