
.cardMod {
    height: 13.375rem;
    max-height: 13.375rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.cardTitle {
    color: #737373;
    font-weight: bold;
}

.cardSubtitle {
    color: #737373;
}

.cardText {
    color: #737373;
    margin-top: 1rem;
    text-align: justify;
}

@media (max-width: 990px) {

    .cardMod {
        display: none;
    }
    
    .cardTitle {
        display: none;
    }
    
    .cardSubtitle {
        display: none;
    }
    
    .cardText {
        display: none;
    }

    .cardModResponsive {
        height: auto;
    }
    
    .cardTitleResponsive {
        color: #737373;
        font-weight: bold;
    }
    
    .cardSubtitleResponsive {
        color: #737373;
    }
    
    .cardTextResponsive {
        color: #737373;
        margin-top: 1rem;
        text-align: justify;
    }
  
  }

  @media (min-width: 990px) {

    .cardModResponsive {
        display: none;
    }
    
    .cardTitleResponsive {
        display: none;
    }
    
    .cardSubtitleResponsive {
        display: none;
    }
    
    .cardTextResponsive {
        display: none;
    }
  
  }