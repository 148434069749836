
.navbarImageLogo {
  width: 5%;
}

.navbarImageZap {
  width: 2%;
}

.navbarCelZap {
  font-weight: bold;
  font-size: 1rem;
  color: #737373;
  margin-left: 1rem;
}

@media (max-width: 990px) {
  .navbarImageLogo {
    width: 10%;
  }

  .navbarImageZap {
    display: none;
  }

  .navbarCelZap {
    display: none;
  }

  .navbarImageZapResponsive {
    width: 12%;
  }
  
  .navbarCelZapResponsive {
    font-weight: bold;
    font-size: 1rem;
    color: #737373;
    margin-left: 1rem;
    vertical-align: bottom;
  }

  .navbarNameResponsive {
    font-size: 1rem;
    font-family: 'Arial';
    color: #737373;
  }

}

@media (min-width: 990px) {

  .navbarImageZapResponsive {
    display: none;
  }
  
  .navbarCelZapResponsive {
    display: none;
  }

  .navbarNameResponsive {
    display: none;
  }

}
  