
.icon {
    font-size: 2rem;
    color: #737373;
    vertical-align: baseline;
}

.email {
    font-size: 1.25rem;
    color: #737373;
    margin-left: 1rem;
}

@media (max-width: 1200px) {
    .icon {
        font-size: 1.5rem;
        color: #737373;
        vertical-align: baseline;
    }

    .email {
        font-size: 1rem;
        color: #737373;
        margin-left: 1rem;
    }
}